"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AjMoon = void 0;
class AjMoon {
    flip(element) {
        if (Math.random() < 0.5) {
            element.classList.toggle('flipx');
        }
        else {
            element.classList.toggle('flipy');
        }
    }
}
exports.AjMoon = AjMoon;
global.ajmoon = new AjMoon();
