// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/normalize.css/normalize.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/app/assets/bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
}

body {
  height: 100%;
  background: #202020 url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat fixed center center;
  background-size: cover;
  color: #f5f5f5;
  font-family: "Raleway", sans-serif;
}
body h1, body h2, body h3, body h4, body h5, body h6 {
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
}
body h1 {
  font-weight: 900;
  color: #ff4500;
}
body h2 {
  color: #6699cc;
}
body a {
  text-decoration: none;
  color: #ff8050;
}
body a:hover, body a:active, body a:focus {
  color: #6699cc;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
@media (max-width: 599px) {
  .container {
    height: 100%;
  }
}
.container .header .wrapper {
  height: 64px;
  padding: 8px 64px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #202020;
  color: #f5f5f5;
}
.container .header .wrapper h1 {
  color: #ff4500;
}
@media (max-width: 599px) {
  .container .header .wrapper {
    padding: 8px 16px;
  }
}
.container .body {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.container .body .wrapper {
  display: flex;
  padding: 8px 64px;
  box-sizing: border-box;
}
@media (max-width: 599px) {
  .container .body .wrapper {
    padding: 8px 16px;
  }
}
.container .body .projects .subheader {
  height: 48px;
  padding: 8px 64px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #202020;
  color: #f5f5f5;
}
@media (max-width: 599px) {
  .container .body .projects .subheader {
    padding: 8px 16px;
  }
}
.container .body .projects .wrapper {
  display: flex;
  padding: 16px 56px;
  box-sizing: border-box;
}
@media (max-width: 599px) {
  .container .body .projects .wrapper {
    padding: 16px 8px;
  }
}
.container .body .projects .wrapper .grid {
  display: flex;
  flex-wrap: wrap;
}
.container .body .projects .wrapper .grid .project {
  width: 128px;
  margin: 8px;
}
@media (max-width: 599px) {
  .container .body .projects .wrapper .grid .project {
    width: calc(50vw - 26px);
  }
}
.container .body .projects .wrapper .grid .project img {
  width: 128px;
}
@media (max-width: 599px) {
  .container .body .projects .wrapper .grid .project img {
    width: calc(50vw - 26px);
  }
}
.container .footer {
  height: 64px;
  padding: 8px 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #202020;
  color: #b5b5b5;
}
@media (max-width: 599px) {
  .container .footer {
    padding: 8px 16px;
  }
}

.flipx {
  transform: scaleX(-1);
}

.flipy {
  transform: scaleY(-1);
}

.flipx.flipy {
  transform: scale(-1, -1);
}`, "",{"version":3,"sources":["webpack://./app/scss/ajmoon.scss","webpack://./app/scss/_colours.scss","webpack://./app/scss/_config.scss","webpack://./app/scss/_header.scss","webpack://./app/scss/_body.scss","webpack://./app/scss/_projects.scss","webpack://./app/scss/_footer.scss"],"names":[],"mappings":"AAGA;EACI,YAAA;AADJ;;AAIA;EACI,YAAA;EACA,yFAAA;EACA,sBAAA;EACA,cCJU;EDKV,kCAAA;AADJ;AAGI;EACI,mBAAA;EACA,qCAAA;AADR;AAGI;EACI,gBAAA;EACA,cCjBA;ADgBR;AAGI;EACI,cClBM;ADiBd;AAII;EACI,qBAAA;EACA,cCxBM;ADsBd;AAIQ;EACI,cC1BE;ADwBd;;AAOA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;AAJJ;AE9BE;EF+BF;IAMQ,YAAA;EAHN;AACF;AGrCE;EACE,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EAEA,yBFXS;EEYT,cFLU;AD2Cd;AGpCI;EACE,cFZE;ADkDR;AEhDE;ECFA;IAgBI,iBAAA;EHsCJ;AACF;AIxDA;EACE,YAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;AJ0DF;AIxDE;EACE,aAAA;EACA,iBAAA;EACA,sBAAA;AJ0DJ;AElEE;EEKA;IAKI,iBAAA;EJ4DJ;AACF;AKzEE;EACE,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EAEA,yBJXS;EIYT,cJLU;AD+Ed;AEjFE;EGFA;IAYI,iBAAA;EL2EJ;AACF;AKxEE;EACE,aAAA;EACA,kBAAA;EACA,sBAAA;AL0EJ;AE3FE;EGcA;IAKI,iBAAA;EL4EJ;AACF;AK1EI;EACE,aAAA;EACA,eAAA;AL4EN;AK1EM;EACE,YAAA;EACA,WAAA;AL4ER;AExGE;EG0BI;IAKI,wBApCU;ELiHlB;AACF;AK3EQ;EACE,YAAA;AL6EV;AEhHE;EGkCM;IAII,wBA3CQ;ELyHlB;AACF;AMxHA;EACE,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EAEA,yBLVW;EKWX,cLHY;AD4Hd;AE/HE;EIHF;IAYI,iBAAA;EN0HF;AACF;;AAtFA;EACI,qBAAA;AAyFJ;;AAvFA;EACI,qBAAA;AA0FJ;;AAxFA;EACI,wBAAA;AA2FJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
